
export const Routes = {
    // pages
    Home: { path: "/" },
    SignIn: { path: "/signin" },
    Profile: { path: "/profile" },
    Register: { path: "/Register" },
    ForgotPassword: { path: "/ForgotPassword" },
    ResetPassword: { path: "/ResetPassword" },

    PlaceDetails: { path: "/places/:placeId", clean: "/places/" },

    SelectOrganization: { path: "/SelectOrganization" },

};