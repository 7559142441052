import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { parsedUser } from "../../common/GetCurrentUser";
import API from "../../common/API";

const AssignCustomer = (props) => {
  const user = parsedUser();
  const [records, setRecords] = useState([]);
  const bindDataRemote = async () => {
    let Query = "OrganizationId=" + user.OrganizationId;

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("customers/list", Query);

    if (requestAPI.data.status === "ok") {
      var result = requestAPI.data.response;
      setRecords(result);
    }
  };

  useEffect(() => {
    bindDataRemote();
  }, []);

  return (
    <>
      <Modal show={props.show} onHide={props.toggle}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0" style={{ overflow: "auto" }}>
          <div className="table-responsive">
            <table className="table table-striped">
              <tbody>
                {records &&
                  records.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => {}}
                          >
                            Assign
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AssignCustomer;
