import React, { useEffect, useState } from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";
import { parsedUser } from "../../common/GetCurrentUser";
import API from "../../common/API";
import moment from "moment";
import Settings from "../../common/Settings";

import GoogleMapReact from "google-map-react";
import PinComponent from "../Common/PinComponent";

const Conversations = (props) => {
  const GoogleMapRef = React.createRef();
  const [records, setRecords] = useState([]);
  const [record, setRecord] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);

  const [theMap, setTheMap] = useState(null);
  const [theMapsReference, setTheMapsReference] = useState(null);
  const handleApiLoaded = (map, mapsReference) => {
    setTheMap(map);
    setTheMapsReference(mapsReference);
  };
  const user = parsedUser();
  const bindDataRemote = async () => {
    var request = await API.getAction("Chat/Chats", "userId=" + user.ID);
    if (request.data.status === "ok") {
      var result = request.data.response;
      console.log(result);
      setRecords(result);
    }
  };

  const bindDataConversationRemote = async () => {
    var request = await API.getAction(
      "Chat/Conversation",
      "id=" + record.id + "&userId=" + user.ID
    );
    if (request.data.status === "ok") {
      var result = request.data.response;
      console.log(result);
      setMessages(result);
    }
  };

  const sendMessage = async () => {
    let query = {
      chatId: record.id,
      senderId: user.ID,
      message: message,
    };
    setMessage("");
    var request = await API.postAction("Chat/SendMessage", query);
    if (request.data.status === "ok") {
      var result = request.data.response;
      console.log(result);
      bindDataConversationRemote();
    }
  };

  const listOfConversations = () => {
    let items =
      records &&
      records.map((item, index) => {
        let path = `${Settings.BasePath}files/${item.picture}`;
        return (
          <Card
            key={index}
            className="my-2 shadow-sm clickable"
            onClick={() => setRecord(item)}
          >
            <Card.Body className="p-1">
              <Row>
                <Col className="col-auto">
                  <img
                    alt=""
                    src={path}
                    className="img-fluid rounded  mx-0"
                    style={{ height: 50, width: 50 }}
                  ></img>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <b>{item.title}</b>
                      <br />
                      <small>{item.lastMessage}</small>
                    </Col>
                    <Col xs={"auto text-end"}>
                      {item.lastMessageDate && (
                        <small className="text-muted ms-2">
                          {moment(item.lastMessageDate).fromNow()}
                        </small>
                      )}
                      <br />
                      <small className="text-muted d-inline text-end">
                        {item.members
                          .filter((x) => x.memberId !== user.ID)
                          .map(
                            (member) =>
                              member.name +
                              " " +
                              member.lastName?.substring(0, 1) +
                              "."
                          )
                          .join(", ")}
                      </small>
                    </Col>
                  </Row>
                </Col>
                <Col className="col-auto">
                  {item.unreadMessages > 0 && (
                    <div className="badge bg-danger">{item.unreadMessages}</div>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        );
      });

    return items;
  };
  const listMessages = () => {
    let actualUser = null;
    let items =
      messages &&
      messages.map((item, index) => {
        let path = `${Settings.BasePath}files/${item.picture}`;
        let messageRow = (
          <div key={index}>
            {actualUser !== item.senderId && (
              <p className="m-0 mt-2 p-0 fw-bold border-top">
                {item.senderName} {item.senderLastName}
              </p>
            )}

            <div className="message">
              {item.message}
              {item.created && (
                <small className="text-muted ms-2 messageTime">
                  {moment(item.created).fromNow()}
                </small>
              )}
            </div>
            {/* {actualUser !== null && actualUser !== item.senderId && (
              <hr className="p-1 m-0" />
            )} */}
          </div>
        );

        actualUser = item.senderId;
        return messageRow;
      });

    return items;
  };
  useEffect(() => {
    if (record) {
      bindDataConversationRemote();
      intervalGetMessages = setInterval(bindDataConversationRemote, 5000);
    } else {
      bindDataRemote();
      intervalGetConversations = setInterval(bindDataRemote, 15000);
    }

    return () => {
      clearInterval(intervalGetMessages);
      clearInterval(intervalGetConversations);
    };
  }, [record]);
  let intervalGetMessages = null;
  let intervalGetConversations = null;
  useEffect(() => {
    bindDataRemote();
  }, []);

  return (
    <Modal show={props.show} onHide={props.toggle} className="chatContainer">
      <Modal.Header closeButton>
        <Modal.Title>
          {record ? (
            <Row className="mx-0">
              <Col className="col-auto">
                <button
                  className="btn btn-sm me-2"
                  onClick={() => setRecord(null)}
                  type="button"
                >
                  <i className="fa fa-chevron-left"></i>
                </button>
                <img
                  alt=""
                  src={`${Settings.BasePath}files/${record.picture}`}
                  className="img-fluid rounded  mx-0"
                  style={{ height: 22, width: 22 }}
                ></img>
              </Col>
              <Col>{record.title}</Col>
            </Row>
          ) : (
            "Conversations"
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="p-0 bg-white"
        style={{ overflow: "auto", minHeight: "84vh" }}
      >
        <Row className="mx-0">
          <Col xs={12} className="my-2">
            {!record ? (
              listOfConversations()
            ) : (
              <>
                <GoogleMapReact
                  ref={GoogleMapRef}
                  // onClick={this.handleClick}
                  yesIWantToUseGoogleMapApiInternals={true}
                  onGoogleApiLoaded={({ map, maps }) =>
                    handleApiLoaded(map, maps)
                  }
                  bootstrapURLKeys={{ key: Settings.GoogleMapsKey }}
                  style={{
                    width: "100%",
                    height: "200px",
                    position: "relative",
                  }}
                  center={{
                    lat: record.placeLatitude,
                    lng: record.placeLongitude,
                  }}
                  defaultZoom={17}
                >
                  <PinComponent
                    Type={"Visit"}
                    lat={record.placeLatitude}
                    //    item={model}
                    // toggle={toggleDetails}
                    lng={record.placeLongitude}
                    text={record.title}
                  />
                </GoogleMapReact>
                {listMessages()}
              </>
            )}
          </Col>
        </Row>
      </Modal.Body>
      {record && (
        <Modal.Footer className="bg-light">
          <Row className="mx-0 w-100">
            <Col>
              <input
                type="text"
                className="form-control"
                placeholder="Message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Col>
            <Col className="text-right col-auto">
              <button
                disabled={message.length === 0}
                className="btn btn-primary"
                type="button"
                onClick={sendMessage}
              >
                Send
              </button>
            </Col>
          </Row>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default Conversations;
