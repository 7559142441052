import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "./routes";

// pages

// components

import Preloader from "./components/Preloader";


import Login from './components/Profile/Login';
import HomePage from './pages/HomePage';
import Navbar from './components/Common/Navbar';
import SelectOrganization from './components/Organizations/SelectOrganization';
import OneSignal from 'react-onesignal';
import Settings from './common/Settings';

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {

    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);


  return (
    <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        <Navbar />
        <Component {...props} />



      </>
    )}
    />
  );
};

export default () => {


  const runOneSignal = async () => {
    await OneSignal.init({ appId: Settings.OneSignalApiKey, allowLocalhostAsSecureOrigin: true });
    OneSignal.Slidedown.promptPush();
  }
  useEffect(() => {
    runOneSignal();
  }, [])


  return (


    // <SocketContext.Provider value={socket}>
    <Switch>
      {/* pages */}
      <RouteWithSidebar exact path={Routes.Home.path} component={HomePage} />
      <RouteWithSidebar exact path={Routes.PlaceDetails.path} component={HomePage} />

      <Route exact path={Routes.SelectOrganization.path} component={SelectOrganization} />
      <Route path={Routes.SignIn.path} component={Login} />
      <Redirect to={Routes.SignIn.path} />
    </Switch>

    // </SocketContext.Provider>
  )
};
