// db.js
import Dexie from 'dexie';

export const db = new Dexie('vo_db');
db.version(1).stores({
  hiddenProjects: '++id', // Primary key and indexed props
  projects: "++id",
  preferences: "++id"
});

db.version(2).stores({
    hiddenProjects: '++id, organizationId', // Primary key and indexed props
    projects: "++id, organizationId",
    preferences: "++id"
  });


db.version(3).stores({
  hiddenProjects: '++id, organizationId', // Primary key and indexed props
  projects: "++id, organizationId",
  preferences: "++id",
  apiLog: "++id"//This is for store every single transaction locally and then push
});