//Dictionaries

//spanish

import global_es_DO from "../translations/es-DO/global.json"

//english
import global_en_US from "../translations/en-US/global.json"

//Here you need add all languages
const languages = [
  { name: "es-DO", code: "es_DO", select: true },
  { name: "en-US", code: "en_US", select: false },
]

//Here you need add all dictionaries
export const resources = {
  es_DO: { global: global_es_DO },
  en_US: { global: global_en_US },
}

export default languages
