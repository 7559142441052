import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import API from "../../common/API";

const TakePicture = (props) => {
  const [model, setModel] = useState(props.model ?? {});
  const [facingMode, setFacingMode] = useState("environment"); //environment or user
  const [displayPhoto, setDisplayPhoto] = useState(false);
  let stream;
  const requestVideo = async () => {
    stream = await navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: facingMode,
      },
      audio: false,
    });
    let video = document.getElementById("img");
    video.srcObject = stream;
  };

  const takepicture = () => {
    let video = document.getElementById("img");
    let canvas = document.getElementById("canvas");
    let photo = document.getElementById("photo");
    const context = canvas.getContext("2d");
    let width = video.videoWidth;
    let height = video.videoHeight / (video.videoWidth / width);
    if (width && height) {
      canvas.width = width;
      canvas.height = height;
      context.drawImage(video, 0, 0, width, height);

      const data = canvas.toDataURL("image/jpeg");
      photo.setAttribute("src", data);
    } else {
      //  clearphoto();
    }

    setDisplayPhoto(true);
  };
  const assign = () => {
    let canvas = document.getElementById("canvas");
    var blob = null;

    canvas.toBlob(async (res) => {
      blob = res;
      var file = new File([blob], "img.jpeg");
      let bodyData = new FormData();
      bodyData.append("path", file);
      var request = await API.postAction(`File/Upload`, bodyData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(request.data);

      let video = document.getElementById("img");
      if (request.data.status === "ok" && video) {
        // video.getTracks().forEach((track) => {
        //   if (track.readyState === "live") {
        //     track.stop();
        //   }
        // });

        props.toggle(request.data.response, props.imageField);
      }
      //  props.toggle(file, canvas.toDataURL("image/jpeg"));
    });
  };
  useEffect(() => {
    requestVideo();
    console.log(facingMode);
  }, [facingMode]);
  useEffect(() => {
    requestVideo();
  }, []);
  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      size="md"
      className="modalRight"
    >
      <Modal.Header closeButton>
        <Col className="col-auto">
          <span>Tomar Foto</span>
        </Col>
      </Modal.Header>
      <Modal.Body className="p-0" style={{ overflow: "auto" }}>
        <Container>
          <Row>
            <Col xs={12}>
              <video
                autoPlay={true}
                playsInline={true}
                id="img"
                className=" w-100"
                style={{
                  minHeight: "100%",
                  objectFit: "contain",
                  display: displayPhoto ? "none" : "block",
                }}
              ></video>
              <canvas id="canvas" style={{ display: "none" }} />

              <img
                id="photo"
                style={{
                  display: !displayPhoto ? "none" : "block",
                }}
              ></img>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="p-1">
        <Row className="mx-0 w-100">
          <Col>
            <button
              className="btn "
              onClick={() => {
                setFacingMode(facingMode === "user" ? "environment" : "user");
              }}
            >
              <i className="fas fa-sync-alt me-2"></i>
              Cambiar
            </button>
            <button
              className={`ms-auto btn  me-2  ${
                displayPhoto ? "btn-success" : "btn-secondary"
              }`}
              onClick={() => {
                !displayPhoto ? takepicture() : setDisplayPhoto(false);
              }}
            >
              <i className="fa fa-camera me-2"></i>
              {displayPhoto ? "Re-tomar" : "Tomar"} foto
            </button>
            {displayPhoto ? (
              <button
                className="btn btn-primary"
                onClick={() => {
                  assign();
                }}
              >
                <i className="fa fa-save me-2"></i>
                Asignar
              </button>
            ) : null}
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default TakePicture;
