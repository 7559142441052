
import React from 'react';
const PinComponent = props => {

  return (<div className={(props.Type === "event" ? "btn-primary" : "btn-danger") + " btn  btn-sm"}
    onClick={() => {
      props.toggle(props.item);
    }}>
    <i className="fa fa-map-marker text-light" title={props.text} />
  </div>)
}

export default PinComponent;