import React, { useEffect, useState } from "react";

import GoogleMapReact from 'google-map-react';
import { Col, Row } from "react-bootstrap";
import Settings from "../../common/Settings";
import AddEditPlace from "./AddEditPlace";
import API from "../../common/API";
import PinComponent from "./PinComponent";
import PlaceDetails from "./PlaceDetails";
import { parsedUser } from "../../common/GetCurrentUser";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "../../routes";
import PinComponentUser from "./PinComponentUser";
import OneSignal from "react-onesignal";

const DashboardOverview = props => {
    const user = parsedUser();
    const history = useHistory();
    const { chatId, placeId } = useParams();
    const GoogleMapRef = React.createRef();
    const [theMap, setTheMap] = useState(null);
    const [theMapsReference, setTheMapsReference] = useState(null);
    const [zoom, setZoom] = useState(17);
    const [displayMode, setDisplayMode] = useState('map')

    const [userPosition, setUserPosition] = useState(null)
    const [places, setPlaces] = useState([])
    const [includeUnassigned, setIncludeUnassigned] = useState(true);
    const [showAddedit, setShowAddEdit] = useState(false);

    const handleApiLoaded = (map, mapsReference) => {
        setTheMap(map);
        setTheMapsReference(mapsReference);
    };

    const userLocation = () => {

        var geoSuccess = function (position) {
            // Do magic with location


            setUserPosition({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            });

            //Now we need to request the information from Google
        };
        var geoError = function (error) {
            switch (error.code) {
                case error.TIMEOUT:
                    // The user didn't accept the callout
                    // showNudgeBanner();
                    break;
            }
        };

        navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
    }

    const toggleAddEdit = (obj) => {

        setSelectedItem(obj);
        setShowAddEdit(!showAddedit);
    }

    const requestPlaces = async () => {
        if (!user) {
            history.push(Routes.SignIn.path);
            return;
        }
        console.log("user", user);

        let query = "assignedTo=" + user.ID;
        query += "&OrganizationId=" + user.OrganizationId;
        if (includeUnassigned) {
            query += "&includeUnassigned=true";
        }
        let requestAPI = await API.getAction("place/List", query);
        if (requestAPI.data.status === "ok") {
            setPlaces(requestAPI.data.response);
        }
    };
    const [showDetails, setShowDetails] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const toggleDetails = obj => {
        debugger;
        history.push(Routes.PlaceDetails.clean + obj.id)
        // setShowDetails(!showDetails);
        // setSelectedItem(obj);
    }
    const [center, setCenter] = useState({ lat: 0, lng: 0 })
    const GetLocation = () => {
        var geoSuccess = function (position) {
            // Do magic with location

            console.log("the position", position.coords);
            setCenter({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            });

            //Now we need to request the information from Google
        };
        var geoError = function (error) {
            switch (error.code) {
                case error.TIMEOUT:
                    // The user didn't accept the callout
                    // showNudgeBanner();
                    break;
            }
        };

        navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
    };

    const [placeShow, setPlaceShow] = useState(false);
    const togglePlace = () => {
        setPlaceShow(!placeShow)
        if (placeShow) {
            history.push(Routes.Home.path);
        }
    }

    useEffect(() => {
        console.log(placeId)

        if (placeId) {
            setPlaceShow(true);
        } else {
            setPlaceShow(false)
        }
    }, [placeId])
    useEffect(() => {
        if (center) {
            centerMap(center);
        }
    }, [center])

    const centerMap = (toPlace) => {
        let withMarker = false;
        if (theMapsReference) {
            const bounds = new theMapsReference.LatLngBounds();
            if (toPlace) {
                bounds.extend(
                    new theMapsReference.LatLng(
                        toPlace.lat,
                        toPlace.lng
                    )
                );
                withMarker = true
            } else {
                places.forEach((marker) => {
                    if (marker.latitude) {

                        withMarker = true;
                        bounds.extend(
                            new theMapsReference.LatLng(
                                marker.latitude,
                                marker.longitude
                            )
                        );
                    }
                });
            }
            if (withMarker) {
                theMap.fitBounds(bounds);
                //theMap.setZoom(zoom);
                console.log("bounds", bounds, zoom);
            }
        }
    };

    useEffect(() => {
        requestPlaces();
        if (!user) {
            window.location.href = Routes.SignIn.path;
        }
        OneSignal.login(user.ID);
        setInterval(() => {
            userLocation();
        }, 5000)
    }, [])

    useEffect(() => {
        requestPlaces();
    }, [includeUnassigned])

    useEffect(() => {
        if (places.length > 0) {
            centerMap();
        }
    }, [places])

    return (<>
        {showAddedit
            ? <AddEditPlace show={showAddedit} toggle={toggleAddEdit} model={selectedItem}></AddEditPlace> : null}
        {placeShow
            ? <PlaceDetails show={placeShow} toggle={togglePlace} model={{ id: placeId }}></PlaceDetails> : null}
        <Row className="m-0 bg-light mx-0">
            <Col className="p-0" style={{ overflowY: "auto" }}>
                {displayMode === 'map' ?
                    <GoogleMapReact ref={GoogleMapRef}
                        // onClick={this.handleClick}
                        yesIWantToUseGoogleMapApiInternals={true}
                        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                        bootstrapURLKeys={{ key: Settings.GoogleMapsKey }}
                        style={{ width: "100%", minHeight: "300px", height: "80vh", position: "relative" }}
                        defaultCenter={{ lat: 18.4718609, lng: -69.8923187 }}
                        defaultZoom={zoom}
                    >{
                            <PinComponentUser Type="user" lat={userPosition ? userPosition.lat : 0} lng={userPosition ? userPosition.lng : 0} />
                        }

                        {places && places
                            .filter(x => x.latitude > 0)
                            .map((item, index) => {

                                return (<PinComponent Type={"Visit"} lat={item.latitude} key={index}
                                    item={item}
                                    toggle={toggleDetails}
                                    lng={item.longitude}
                                    text={item.name} />);
                            })}
                    </GoogleMapReact> :
                    <div className="mx-4" style={{ height: "80vh" }}>
                        {places && places.map((item, index) => {
                            return (<div className="card shadow-sm my-4" key={index}>
                                <img className="card-img-top" src={`${Settings.BasePath}files/${item.image}`} alt={item.name} onClick={() => toggleDetails(item)}></img>
                                <div className="card-body" onClick={() => toggleDetails(item)}>
                                    <Row className="0">
                                        <Col>
                                            <b>{item.name}</b>
                                            <p className="card-text text-truncate">
                                                {`${item.address ?? ""}, ${item.sector ?? ""}, ${item.city ?? ""}, ${item.region ?? ""}, ${item.country}`}
                                            </p>
                                        </Col>
                                        <Col className="text-end col-auto">
                                            <i className={item.stage.icon} />
                                            <span className={"badge  ms-2 " + (item.visits === 0 ? "bg-danger" : "bg-info")}>Visita: {item.visits}</span>
                                        </Col>
                                    </Row>

                                </div>
                                <div className="card-footer">
                                    <button
                                        className="btn btn-success btn-sm"
                                        type="button"
                                        onClick={() => {
                                            let fullAddress = "";
                                            fullAddress = `${item.latitude},${item.longitude}`
                                            //`${model.item.streetAddress ?? ""}, ${model.item.city ?? ""}, ${model.item.state ?? ""}, ${model.item.country}`;
                                            window.open(
                                                `https://www.google.com/maps/search/?api=1&query=${fullAddress
                                                }`,
                                                "MapWindow",
                                                "width=1280,height=840"
                                            );
                                        }}
                                    >GO
                                    </button>
                                </div>

                            </div>)
                        })}
                    </div>}
            </Col>
        </Row>
        <Row className="m-0 bg-white py-2" style={{ height: "10vh" }}>
            <Col className="py-2">
                <button className=" btn btn-primary rounded mx-2"
                    onClick={() => {
                        toggleAddEdit()
                    }}>
                    <i className="fa  fa-plus"></i>
                </button>

            </Col>
            <Col className="text-end py-2">
                <div className="mainAction">
                    <button className=" btn  btn-secondary rounded me-2"
                        onClick={() => {
                            GetLocation()
                        }}>
                        <i className="fas  fa-map-marker-alt"></i>
                    </button>
                    <button className={` btn rounded me-2 ` + (includeUnassigned ? "btn-primary" : "btn-secondary")}
                        title="Incluir Lugares sin Asignar"
                        onClick={() => {
                            setIncludeUnassigned(!includeUnassigned)

                        }}>
                        <i className="fas fa-user-slash"></i>
                    </button>
                    <button className=" btn  btn-secondary rounded me-2"
                        onClick={() => {
                            if (displayMode === 'map') {
                                setDisplayMode('list')
                            } else {
                                setDisplayMode('map')
                            }
                        }}>
                        {displayMode === 'list' ? <i className="fas fa-map"></i> : <i className="fas fa-list"></i>}
                    </button>
                    {/* <button className=" btn btn-secondary rounded"
                onClick={() => {
                    toggleAddEdit()
                }}>
                <i class="fas fa-2x fa-map-marker-alt me-2"></i> Sin Asignar
            </button> */}
                </div>

            </Col>
        </Row>

    </>)
}

export default DashboardOverview;