import moment from "moment";
import React from "react";
const PinComponentUser = (props) => {
  const date1 = moment();
  const date2 = moment(props.item?.created);
  const diff = date1.diff(date2, "days");

  return (
    <div
      className={"fas fa-circle text-white shadow-sm userDot " + " fa-3x "}
      onClick={() => {
        //   props.toggle(props.item);
      }}
    >
      <i
        className="fas fa-circle text-info blink"
        style={{
          fontSize: "0.6em",
          position: "absolute",
          left: "0.35em",
          top: "7px",
        }}
      />
    </div>
  );
};

export default PinComponentUser;
